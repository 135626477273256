<template>
  <page fluid="lg" title="Editar usuario">
    <formulario :usuario="usuario" @submit="actualizar"> </formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiUsuarios from "@/services/usuarios.js";

export default {
  components: { Formulario },
  data() {
    return {
      usuario: {},
      imagenes: {},
    };
  },
  methods: {
    async cargar(id) {
      let resultado = await apiUsuarios.cargar(id);
      this.usuario = resultado;
    },
    async actualizar() {
      let ok = await apiUsuarios.actualizar(this.usuario);
      if (ok) {
        this.$emit("userMessage", "Se ha actualizado el usuario");
        this.$router.push({ path: "/usuarios" });
      }
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
